import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_CLOCK,
  Button,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { getParamUrl } from "@ca-dmv-radv/utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { APPLICATION_TYPE } from "@ca-dmv-radv/data";

function DynamicExpiredScreen({ title, applicationType }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        <div className="mb-40 mt-40">
          <Icon
            icon={ICON_CLOCK}
            isDecorative
            className="w--100 max-width--300 bp-md:max-width--400"
          />
        </div>
        <h2 className="h3 max-width--800 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
          {t("screens-MDL-expired-heading", "Oops!")}
        </h2>
        <p className="text--sm mb-24 text--center max-width--600">
          {t("screens-MDL-expired-message", "The link you are using has expired.")}
        </p>
        {applicationType !== APPLICATION_TYPE.ODP && (
          <ButtonWrapper wrapperClass="flex--col-reverse mt-15 bp-md:mt-60">
            <Button
              buttonClass="mb-24 bp-md:mb-0"
              label={t(
                "screens-MDL-buttonLabel-generate-new",
                "Request a New Link"
              )}
              onClick={() => {
                navigate(`/${applicationType}/search`, { state: { token: location?.state?.token }});
                //window.location = getParamUrl(`/mdl/search`);
              }}
            />
          </ButtonWrapper>
        )}
      </div>
    </RadvPageWrapper>
  );
}

export default DynamicExpiredScreen;